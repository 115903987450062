export class OsChecker {
  userAgent = navigator.userAgent

  /** MacやiPhone・iPadならTrueを返す */
  isIOS(): boolean {
    return /iPhone|iPad|iPod|Macintosh/i.test(this.userAgent)
  }

  /** AndroidならTrueを返す */
  isAndroid(): boolean {
    return /Android/i.test(this.userAgent)
  }

  /** PCならTrueを返す */
  isPc(): boolean {
    return /Windows|X11/.test(this.userAgent) || (/Macintosh/.test(this.userAgent) && /CriOS|FxiOS|OPiOS|EdgiOS|Version|Chrome|Safari/i.test(this.userAgent))
  }

  /** ブラウザならTrueを返す */
  isBrowser(): boolean {
    return /Chrome|Firefox|Opera|Edge|SamsungBrowser|CriOS|FxiOS|OPiOS|EdgiOS|Version/i.test(this.userAgent) && !/wv/.test(this.userAgent)
  }
}
